import { useEffect } from 'react';
import { Container } from 'components/Styles';
import { Typography } from '@mui/joy';
import { ReactComponent as SessionExpiredIcon } from 'assets/icons/error-icon-big.svg';
import { useModalContext } from 'components/Modal/Modal.context';

export const SessionExpired = () => {
  const { setDimensions } = useModalContext();

  useEffect(() => {
    setDimensions({
      width: 456,
      height: 628,
    });
  }, [setDimensions]);

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <SessionExpiredIcon />
      <Typography
        level="h1"
        sx={{
          mt: 2,
          mb: 1,
        }}
      >
        Session Expired
      </Typography>
      <Typography level="body1" sx={{ color: 'common.grey' }}>
        To start a new session, close this tab and click on the TrueToForm 'SEND
        MEASUREMENTS' button again.
      </Typography>
    </Container>
  );
};
