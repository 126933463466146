import { isInsideIframe } from './isInsideIframe';

export const isWidgetIframe = () => {
  const isIframe = isInsideIframe();
  const params = new URLSearchParams(window.location.search);
  const hasWidgetParams =
    params.has('apiKey') && params.has('clientId') && params.has('sessionId');
  const hasPathNames = window.location.pathname !== '/';
  const modalIsVisible = document.getElementById('ttf-widget-modal');
  // fix for login page but isWidgetIframe => false
  const isWidget = hasWidgetParams || hasPathNames || modalIsVisible;
  return isIframe && Boolean(isWidget);
};
