export type ErrorTemplate = {
  message: string;
  element: string;
};

export function getFirebaseAuthError(error: any): ErrorTemplate {
  if (!error) {
    return {
      message: '',
      element: '',
    };
  }
  const { code } = error;
  switch (code) {
    case 'auth/email-already-in-use':
      return {
        message: 'Email already registered',
        element: 'email',
      };
    case 'auth/invalid-email':
      return {
        message: 'Invalid email',
        element: 'email',
      };
    case 'auth/weak-password':
      return {
        message: 'Password is too weak',
        element: 'password',
      };
    case 'auth/wrong-password':
      return {
        message: 'Wrong password',
        element: 'password',
      };
    case 'auth/user-not-found':
      return {
        message: 'User not found',
        element: 'email',
      };
    case 'auth/invalid-credential':
      return {
        message: 'Invalid credential',
        element: 'email',
      };
    case 'auth/opreation-not-allowed':
      return {
        message: 'Operation not allowed',
        element: 'email',
      };
    default:
      return {
        message: 'An error occurred. Please try again.',
        element: 'email',
      };
  }
}
