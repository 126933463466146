import {
  useEffect,
  useState,
  createContext,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import {
  onSnapshot,
  setDoc,
  Timestamp,
  DocumentReference,
  Unsubscribe,
  doc,
} from 'firebase/firestore';
import { useErrorHandler } from 'react-error-boundary';
import { SessionDoc } from 'db/types';
import { db } from 'db/base';
import { useAPIKeyDoc } from 'hooks/useAPIKeyDoc';
import { isInsideIframe } from 'utils/isInsideIframe';
import { isWidgetIframe } from 'utils/isWidgetIframe';
import { createSessionQRLink } from 'utils/createSessionQRLink';
import { retry } from 'utils/retry';
import { sleep } from 'utils/sleep';
import { trackEvent } from 'utils/trackEvent';

interface SessionState {
  data: SessionDoc | null;
  loading: boolean;
  createNewSession: (docId: string) => Promise<SessionDoc | undefined>;
  qrLink: string | null;
  qrLoading: boolean;
}

export const SessionContext = createContext<SessionState>({
  data: null,
  loading: false,
  createNewSession: async () => undefined,
  qrLink: null,
  qrLoading: false,
});

type CreateSessionParams = {
  clientId: string;
  origin: string;
  onUpdate: (sessionDoc: SessionDoc) => void;
  handleError: (error: Error) => void;
  docId: string;
};

type GetSessionParams = {
  docId: string;
  origin: string;
  onUpdate: (sessionDoc: SessionDoc) => void;
  handleError: (error: Error) => void;
};

const RETRIES_TIMEOUT = 1000 * 6;
const NOW = Date.now();

async function getSession({
  docId,
  origin,
  onUpdate,
  handleError,
}: GetSessionParams): Promise<Unsubscribe | undefined> {
  let isInvalidSession = true; // should be defaulted to true and if doc exists, set to false
  if (docId) {
    const docRef = doc(db, 'widget_sessions', docId) as DocumentReference<
      Omit<SessionDoc, 'ref'>
    >;
    const unsubscribeFn = onSnapshot(docRef, (sessionDoc) => {
      if (sessionDoc.exists()) {
        isInvalidSession = false;
        const sessionExpired =
          sessionDoc.data().expiresAt.toMillis() < Date.now();
        if (sessionExpired) {
          return handleError(
            new Error(
              'Session has expired, please refresh the previous page and try again.'
            )
          );
        }
        if (sessionDoc.data().origin !== origin) {
          return handleError(new Error('Permission denied!'));
        }
        onUpdate({
          ...sessionDoc.data(),
          ref: docRef,
        });
      } else {
        isInvalidSession = true;
        const shouldRetry = Date.now() - NOW < RETRIES_TIMEOUT;
        if (!shouldRetry) {
          handleError(new Error('Invalid session ID'));
        }
      }
    });
    await sleep(500); // must wait for onSnapshot to be called before returning unsubscribeFn (don't remove this)
    if (isInvalidSession) throw new Error('Invalid session ID');
    return unsubscribeFn;
  }
}

async function createSession({
  clientId,
  origin,
  onUpdate,
  handleError,
  docId,
}: CreateSessionParams): Promise<SessionDoc | undefined> {
  const TWO_HOURS = 1000 * 60 * 60 * 2;
  const docRef = doc(db, 'widget_sessions', docId) as DocumentReference<
    Omit<SessionDoc, 'ref'>
  >;
  await setDoc(docRef, {
    clientId,
    origin,
    createdAt: Timestamp.fromMillis(Date.now()),
    expiresAt: Timestamp.fromMillis(Date.now() + TWO_HOURS),
    data: null,
    activeScan: null,
    progress: 'IDLE', // should be in initial state or rules will throw error
  });

  onSnapshot(docRef, (sessionDoc) => {
    if (sessionDoc.exists()) {
      const sessionExpired =
        sessionDoc.data().expiresAt.toMillis() < Date.now();
      if (sessionExpired) {
        handleError(
          new Error(
            'Session expired, please start new session by refreshing the page'
          )
        );
      }
      onUpdate({
        ...sessionDoc.data(),
        ref: docRef,
      });
    } else {
      handleError(new Error('Invalid session ID'));
    }
  });
  return {
    clientId,
    origin,
    createdAt: Timestamp.fromMillis(Date.now()),
    expiresAt: Timestamp.fromMillis(Date.now() + TWO_HOURS),
    data: null,
    activeScan: null,
    progress: 'IDLE',
    ref: docRef,
  };
}

export function SessionContextProvider(props: { children: React.ReactNode }) {
  const [data, setData] = useState<SessionDoc | null>(null);
  const [loading, setLoading] = useState(true);
  const { data: apiKeyDoc } = useAPIKeyDoc();
  const { clientId = '' } = apiKeyDoc ?? {};
  const handleError = useErrorHandler();
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get('sessionId');
  const redirectUrl = urlParams.get('redirectUrl');
  // the origin is the URL of the host page
  const origin = redirectUrl
    ? new URL(redirectUrl).origin
    : apiKeyDoc?.origin ?? '';
  const [qrLoading, setQrLoading] = useState(false);
  const [qrLink, setQrLink] = useState<string | null>(null);

  useEffect(() => {
    let unsubscribe: Unsubscribe | undefined;
    async function init() {
      try {
        const isValidIframe = isInsideIframe()
          ? isWidgetIframe()
          : !isInsideIframe();
        if (clientId && origin && sessionId && isValidIframe) {
          setLoading(true);
          try {
            unsubscribe = await retry(
              () =>
                getSession({
                  docId: sessionId,
                  origin,
                  onUpdate: setData,
                  handleError,
                }),
              {
                retries: 6,
                delay: 1000,
              }
            );
            setLoading(false);
          } catch (err) {
            setLoading(false);
            handleError(err);
          }
          const fetchQR = async () => {
            if (apiKeyDoc && !qrLink) {
              setQrLoading(true);
              const qr = await createSessionQRLink(apiKeyDoc.clientId);
              setQrLink(qr);
            }
            setQrLoading(false);
            trackEvent('widget_qr_image_loaded', {
              clientId: apiKeyDoc?.clientId ?? '',
              sessionId,
            });
          };
          fetchQR();
        }
      } catch (error) {
        setLoading(false);
        handleError(error);
      }
    }
    init();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [sessionId, clientId, origin, handleError]);

  const createNewSession = useCallback(
    async (docId: string) => {
      try {
        setLoading(true);
        const createdSession = await createSession({
          clientId,
          origin,
          onUpdate: setData,
          handleError,
          docId,
        });
        setLoading(false);
        return createdSession;
      } catch (error) {
        setLoading(false);
        handleError(error);
      }
    },
    [clientId, origin, handleError]
  );

  const value = useMemo(
    () => ({ data, loading, createNewSession, qrLoading, qrLink }),
    [data, loading, createNewSession, qrLoading, qrLink]
  );

  return <SessionContext.Provider value={value} {...props} />;
}

export function useSession() {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
}
