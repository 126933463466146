import { Typography } from '@mui/joy';
import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import locale from 'date-fns/locale/en-US';

const ONE_WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000;

function formatRelativeDate(date: Date | number) {
  const now = new Date();
  const diff = now.getTime() - new Date(date).getTime();
  // default format says 7 days ago, but we want to say 1 week ago
  // 👇 https://github.com/date-fns/date-fns/issues/1706#issuecomment-836601089 👇
  const resultWithoutLocale = formatDistance(new Date(date), now, {
    addSuffix: true,
  });
  const formatDistanceLocal = (token: string, count: number, options: any) => {
    if (token === 'xDays' && count === 7) {
      if (options.comparison > 0) {
        return 'in 1 week';
      }
      return '1 week ago';
    }
    return resultWithoutLocale;
  };
  // if date is one week or less ago format as relative time
  // else format as date
  if (diff < ONE_WEEK_IN_MS) {
    return formatDistance(new Date(date), now, {
      addSuffix: true,
      locale: {
        ...locale,
        formatDistance: formatDistanceLocal,
      },
    });
  }

  return format(date, 'MMM dd yyyy');
}

export const DateFormatted = ({ date }: { date: number }) => {
  // check if number is milliseconds if not convert to milliseconds
  const dateToFormat = date.toString().length === 10 ? date * 1000 : date;

  return (
    <Typography level="body2" sx={{ color: 'text.secondary' }}>
      {formatRelativeDate(dateToFormat)}
    </Typography>
  );
};
