import { Typography, Sheet } from '@mui/joy';
import { ReactComponent as BackIcon } from 'assets/icons/arrow-left-icon.svg';
import { useNavigate } from 'react-router-dom';

type BackBarProps = {
  header?: string;
};

export const BackBar = ({ header }: BackBarProps) => {
  const navigate = useNavigate();
  return (
    <Sheet
      sx={{ p: 3, display: 'flex', gap: 2, alignItems: 'center' }}
      className="backbar"
    >
      <Sheet
        component="button"
        sx={{
          all: 'unset',
          cursor: 'pointer',
          width: 24,
          height: 24,
        }}
        onClick={() => {
          navigate(-1);
        }}
      >
        <BackIcon />
      </Sheet>
      {header && (
        <Typography level="h1" data-testid="QR-header">
          {header}
        </Typography>
      )}
    </Sheet>
  );
};
