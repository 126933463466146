import { Suspense, useEffect, useState } from 'react';
import { Modal as JoyModal, Sheet } from '@mui/joy';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'context/AuthContextProvider';
import { useAPIKeyDoc } from 'hooks/useAPIKeyDoc';
import { useSession } from 'context/SessionProvider';
import { useMessagesApi } from 'context/MessagesApiProvider';
import { isInsideIframe } from 'utils/isInsideIframe';
import { isWidgetIframe } from 'utils/isWidgetIframe';
import { MessageType } from 'context/types';
import CloseConfirmation from 'pages/CloseConfirmation';
import { ModalProps } from './Modal.types';
import { ModalHeader } from './ModalHeader.component';
import { SuspenseLoader } from '../Styles';
import { useModalContext } from './Modal.context';
import { BREAK_POINTS } from '../../joyTheme';

export function Modal({ children }: ModalProps) {
  const [isMounted, setIsMounted] = useState(false);
  const {
    dimensions,
    isResizing,
    setDimensions,
    showCloseConfirmation,
    openCloseConfirmation,
  } = useModalContext();
  const { isLoading: apiKeyLoading } = useAPIKeyDoc();
  const { loading: sessionLoading, data } = useSession();
  const isDesktop = useMediaQuery({ query: BREAK_POINTS.DESKTOP.query });
  const isTablet = useMediaQuery({ query: BREAK_POINTS.TABLET.query });
  const { currentUser } = useAuth();
  const location = useLocation();
  const reactRouterPathname = location.pathname;
  const { sendMessage } = useMessagesApi();

  useEffect(() => {
    // check if the window isn't an iframe
    if (!isInsideIframe() || isWidgetIframe()) {
      setIsMounted(true);
    }
  }, []);

  useEffect(() => {
    const locationSubscription = () => {
      // set the pathname to the current location from Browser native API
      const { pathname } = window.location;
      const loading = apiKeyLoading || sessionLoading;
      const isLandingPage =
        ((pathname === '/' && !currentUser) || pathname === '/create') &&
        !loading;
      if (isDesktop && isLandingPage) {
        setDimensions({
          width: 800,
          height: 488,
        });
      } else if (isTablet && isLandingPage) {
        setDimensions({
          width: 600,
          height: 535,
        });
      } else if (!isLandingPage && !loading) {
        setDimensions({
          width: 456,
          height: 628,
        });
      }
    };
    locationSubscription();
  }, [
    isDesktop,
    isTablet,
    setDimensions,
    currentUser,
    apiKeyLoading,
    sessionLoading,
    location,
    reactRouterPathname,
  ]);

  const handleOnClose = (_: Event, reason: string) => {
    let isExpired = false;
    if (data) {
      isExpired = data.expiresAt.toMillis() < Date.now();
    }

    if (reason === 'closeClick') {
      if (
        data &&
        (data.progress === 'STARTED' || data.progress === 'SUBMITTING') &&
        reason === 'closeClick' &&
        !isExpired
      ) {
        return openCloseConfirmation();
      }
      if (isWidgetIframe()) {
        return sendMessage({
          type: MessageType.WIDGET_CLOSED,
          payload: {},
        });
      }
      setIsMounted(false);
      window.close();
    }
  };

  return (
    <JoyModal
      disableAutoFocus
      open={isMounted}
      onClose={handleOnClose}
      id="ttf-widget-modal"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: !isWidgetIframe() ? 'common.lightGrey' : 'transparent',
        backdropFilter: !isWidgetIframe() ? 'blur(8px)' : 'none',
        WebkitTapHighlightColor: 'transparent',
        '& > div[open]': {
          backgroundColor: !isWidgetIframe()
            ? 'common.lightGrey'
            : 'transparent',
          backdropFilter: !isWidgetIframe() ? 'blur(8px)' : 'none',
        },
      }}
    >
      <Sheet
        sx={(theme) => ({
          width: dimensions.width,
          maxWidth: '100%',
          maxHeight: '100%',
          backgroundColor: theme.palette.background.body,
          boxShadow: theme.vars.shadow.md,
          minHeight: dimensions.height,
          borderRadius: 8,
          overflow: 'hidden',
          transition: 'width .2s,min-height .2s, transform .2s',
          transitionTimingFunction: 'ease-out',
          mx: 3,
        })}
      >
        <Suspense fallback={<SuspenseLoader />}>
          <ModalHeader />
          <Sheet
            sx={{
              opacity: isResizing ? 0 : 1,
              transition: 'all .2s ease-out',
            }}
          >
            {isResizing ? (
              <Sheet
                sx={{
                  height: dimensions.height - 40,
                  width: dimensions.width,
                }}
              />
            ) : showCloseConfirmation ? (
              <CloseConfirmation />
            ) : (
              children
            )}
          </Sheet>
        </Suspense>
      </Sheet>
    </JoyModal>
  );
}
