import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { ReactComponent as ErrorIcon } from 'assets/icons/error-icon.svg';
import { FormProps } from './types';

export const Form = ({ onSubmit, children, error }: FormProps) => (
  <Sheet component="form" onSubmit={onSubmit}>
    {error && error.message && (
      <Sheet
        sx={(theme) => ({
          backgroundColor: '#FF627E14',
          color: theme.palette.danger.solidBg,
          py: 0.25,
          px: 1.5,
          mb: 1,
          borderRadius: 6,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '9.33px',
        })}
      >
        <ErrorIcon />
        <Typography
          level="h3"
          sx={{
            color: 'var(--ttf-color-danger-soft-bg)',
          }}
          data-testid="login-error-message"
        >
          {error.message}
        </Typography>
      </Sheet>
    )}
    {children}
  </Sheet>
);
