import React, { useRef } from 'react';
import { Typography, Sheet } from '@mui/joy';
import { useModalContext } from 'components/Modal/Modal.context';
import { useLocation, Navigate, Link } from 'react-router-dom';
import { useAuth } from 'context/AuthContextProvider';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { QRImage } from 'components/QRImage';
import { QrFooter } from 'components/templates/QrTemplate/QrFooter';
import { Footer } from 'components/Footer/Footer.component';
import { useSession } from 'context/SessionProvider';
import { BackBar } from 'components/BackBar';
import { isWidgetIframe } from 'utils/isWidgetIframe';
import { BREAK_POINTS } from '../../joyTheme';

const MatchRoute = ({ to, children }: { to: string; children: any }) => {
  const { pathname } = useLocation();
  return pathname === to ? children : null;
};

const QRGradient = () => {
  const isDesktop = useMediaQuery({ query: BREAK_POINTS.DESKTOP.query });
  const { qrLink, qrLoading } = useSession();
  const gradientContainer = useRef<HTMLDivElement>(null);

  return (
    <Sheet
      ref={gradientContainer}
      data-testid="QR-gradient"
      sx={{
        background:
          'linear-gradient(44.36deg, #E6CFFF 0%, #DBDBFF 50%, #C7EEFF 100%)',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: !isDesktop ? 'center' : 'flex-start',
        minHeight: isDesktop ? 'auto' : '228px',
        width: isDesktop ? '50%' : 'auto',
        '& .backbar': {
          alignSelf: 'stretch',
          background: 'transparent',
          py: 1,
          px: 2,
        },
        position: 'relative',
      }}
    >
      <Sheet
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          background:
            'linear-gradient(44.36deg, #DBDBFF 0%, #C7EEFF 50%, #E6CFFF 100%)',
          animation: 'gradient 5s ease-out infinite alternate',
          backgroundSize: '500% 500%',
        }}
      />
      <MatchRoute to="/create">
        <MediaQuery maxWidth={BREAK_POINTS.DESKTOP.value}>
          <BackBar />
        </MediaQuery>
      </MatchRoute>
      <QRImage src={qrLink as string} isLoading={qrLoading} />
      <Typography
        level="h3"
        sx={{
          p: 0,
          fontSize: 16,
          fontWeight: 600,
          lineHeight: '26px',
          letterSpacing: '0.02em',
          minHeight: '26px',
          height: '26px',
          color: 'common.mediumBlue',
          textDecoration: 'none',
          zIndex: 1,
        }}
        component="a"
        href={isWidgetIframe() ? '#' : (qrLink as string)}
        data-testid="QR-link"
        onClick={() => {
          if (window.top) {
            window.top.postMessage(
              {
                type: 'QR_LINK_CLICKED',
                payload: {
                  linkUrl: qrLink,
                },
              },
              '*'
            );
          }
        }}
      >
        Go to link
      </Typography>
      <MediaQuery minWidth={BREAK_POINTS.DESKTOP.value}>
        <QrFooter />
      </MediaQuery>
    </Sheet>
  );
};

const GradientText = ({ children }: { children: string }) => (
  <span
    style={{
      background:
        'linear-gradient(83.69deg, #45A1E4 3.1%, #C58BFF 32.52%, #A28BFF 59.57%)',
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    }}
  >
    {children}
  </span>
);

const TextSection = () => {
  const isDesktop = useMediaQuery({ query: BREAK_POINTS.DESKTOP.query });
  const { currentUser } = useAuth();
  const { pathname } = useLocation();
  return (
    <Sheet
      sx={{
        width: isDesktop ? '50%' : 'auto',
        display: 'flex',
        flexDirection: 'column',
        height: 'inherit',
      }}
    >
      <MatchRoute to="/create">
        <MediaQuery minWidth={BREAK_POINTS.DESKTOP.value}>
          <BackBar />
        </MediaQuery>
      </MatchRoute>
      <Sheet
        sx={{
          p: isDesktop ? 4 : 2,
          pt:
            pathname === '/create' && isDesktop ? '19.5px' : isDesktop ? 5 : 2,
        }}
      >
        <Typography
          level="h1"
          sx={{
            fontSize: isDesktop ? 36 : 24,
            fontWeight: 700,
            lineHeight: !isDesktop ? '32px' : '42px',
            textAlign: !isDesktop ? 'center' : 'left',
          }}
        >
          <MatchRoute to="/">
            <MediaQuery minWidth={BREAK_POINTS.DESKTOP.value}>
              <GradientText>Send your</GradientText>{' '}
              <GradientText>measurements</GradientText> 👉
            </MediaQuery>
            <MediaQuery maxWidth={BREAK_POINTS.DESKTOP.value}>
              <GradientText>Send your measurements</GradientText>
            </MediaQuery>
          </MatchRoute>
          <MatchRoute to="/create">
            <MediaQuery minWidth={BREAK_POINTS.DESKTOP.value}>
              <GradientText>Create & send a</GradientText>{' '}
              <GradientText>new avatar</GradientText>
            </MediaQuery>
            <MediaQuery maxWidth={BREAK_POINTS.DESKTOP.value}>
              <GradientText>Create & send a new avatar</GradientText>
            </MediaQuery>
          </MatchRoute>
        </Typography>
        <MatchRoute to="/">
          <Typography
            sx={{
              fontSize: !isDesktop ? 18 : 24,
              fontWeight: 800,
              lineHeight: !isDesktop ? '26px' : '20px',
              letterSpacing: !isDesktop ? '0.01em' : '0.02em',
              textAlign: !isDesktop ? 'center' : 'left',
              mt: isDesktop ? 1.5 : 0,
            }}
          >
            from a quick body scan
          </Typography>
        </MatchRoute>
        <Typography
          level="body3"
          sx={{
            mt: isDesktop && pathname === '/create' ? 3 : isDesktop ? 5 : 2,
            mb: isDesktop ? '11px' : 2,
            textAlign: isDesktop ? 'left' : 'center',
            fontSize: 14,
            lineHeight: '24px',
            width: '100%',
          }}
        >
          Scan this QR code using an{' '}
          <MediaQuery minWidth={BREAK_POINTS.DESKTOP.value}>
            <br />
          </MediaQuery>
          <b style={{ color: 'black', lineHeight: '22px' }}>
            iPhone with Face ID or an iPad Pro
          </b>{' '}
          <MediaQuery minWidth={BREAK_POINTS.DESKTOP.value}>
            <br />
          </MediaQuery>
          to take a scan using the TrueToForm mobile app.
        </Typography>
        {!currentUser && (
          <Typography
            level="body3"
            sx={{
              textAlign: isDesktop ? 'left' : 'center',
              letterSpacing: '0.02em',
            }}
          >
            Already have a TrueToForm account?{' '}
            <Typography
              component={Link}
              to="login"
              color="primary"
              sx={{
                textDecoration: 'none',
                letterSpacing: '0.02em',
                fontWeight: 600,
              }}
              data-testid="login-link"
            >
              Log in
            </Typography>
          </Typography>
        )}
      </Sheet>
      <MatchRoute to="/">
        <MediaQuery minWidth={BREAK_POINTS.DESKTOP.value}>
          <Sheet sx={{ mx: 3, mt: 'auto' }}>
            <Footer />
          </Sheet>
        </MediaQuery>
      </MatchRoute>
      <MediaQuery maxWidth={BREAK_POINTS.DESKTOP.value}>
        <QrFooter />
      </MediaQuery>
    </Sheet>
  );
};

const LandingPage = () => {
  const { dimensions } = useModalContext();
  const { currentUser } = useAuth();
  const location = useLocation();
  const { pathname } = location;
  const isDesktop = useMediaQuery({ query: BREAK_POINTS.DESKTOP.query });

  if (currentUser && pathname === '/') {
    return <Navigate to="/scans" />;
  }

  return (
    <Sheet
      sx={{
        height: dimensions.height - 40,
        display: 'flex',
        flexDirection: !isDesktop ? 'column' : 'row-reverse',
      }}
      data-testid="landing-page"
    >
      <QRGradient />
      <TextSection />
    </Sheet>
  );
};

export default LandingPage;
