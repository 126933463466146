import { styled, keyframes } from '@mui/system';

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
`;

const LoaderStyles = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  animation: `${spin} .6s  linear infinite`,
}));

export function Loader() {
  return (
    <LoaderStyles data-testid="spinner">
      <img src="/images/loader.png" alt="loading" width="42px" height="42px" />
    </LoaderStyles>
  );
}
