import { Sheet, Typography } from '@mui/joy';
import { ReactComponent as LinkIcon } from 'assets/icons/link-icon.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram-icon.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter-icon.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedin-icon.svg';

const links = {
  website: 'https://truetoform.fit',
  instagram: 'https://www.instagram.com/truetoform_fit',
  linkedin: 'https://www.linkedin.com/company/truetoform-fit',
  twitter: 'https://twitter.com/truetoform_fit',
};

export function Footer() {
  return (
    <Sheet sx={{ backgroundColor: '#fff', marginTop: '-2px' }}>
      <Sheet
        sx={{
          p: 1.5,
          pb: '18px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="body4">
          Follow us for new features & updates
        </Typography>
        <Sheet sx={{ display: 'flex', gap: 1.5 }}>
          <a href={links.website} target="_blank" rel="noreferrer">
            <LinkIcon />
          </a>
          <a href={links.instagram} target="_blank" rel="noreferrer">
            <InstagramIcon />
          </a>
          <a href={links.linkedin} target="_blank" rel="noreferrer">
            <LinkedinIcon />
          </a>
          <a href={links.twitter} target="_blank" rel="noreferrer">
            <TwitterIcon />
          </a>
        </Sheet>
      </Sheet>
    </Sheet>
  );
}
