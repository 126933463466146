import React, { useState, useEffect } from 'react';
import { Sheet } from '@mui/joy';
import ReactQRCode from 'react-qr-code';
import { useMediaQuery } from 'react-responsive';
import { Loader } from '../Loader';
import { BREAK_POINTS } from '../../joyTheme';

export const QRImage = ({
  src,
  isLoading,
}: {
  src: string | null;
  isLoading: boolean;
}) => {
  const [isLogoLoaded, setIsLogoLoaded] = useState(false);
  const isDesktop = useMediaQuery({ query: BREAK_POINTS.DESKTOP.query });
  const [logoUrl, setLogoUrl] = useState('');

  useEffect(() => {
    // Safari will reload the image again if we used the Image API so we use the FileReader API
    // to load the image from the blob and then set it as the src
    async function loadLogo() {
      setIsLogoLoaded(false);
      // load logo from local storage if it exists
      const url = localStorage.getItem('logoUrl');
      if (url) {
        setLogoUrl(url);
        setIsLogoLoaded(true);
        return;
      }
      const reader = new FileReader();
      const logoBlob = await fetch(
        process.env.REACT_APP_TTF_LOGO as string
      ).then((r) => r.blob());
      reader.readAsDataURL(logoBlob);
      reader.onloadend = () => {
        const base64data = reader.result;
        // save url to local storage
        localStorage.setItem('logoUrl', base64data as string);
        setLogoUrl(base64data as string);
        setIsLogoLoaded(true);
      };
    }
    loadLogo();
  }, []);

  const renderImage = () => {
    if (isLoading || !isLogoLoaded) {
      return <Loader />;
    }

    if (!src) {
      return null;
    }

    return (
      <Sheet sx={{ position: 'relative' }} data-testid="QR-image">
        <>
          <ReactQRCode
            value={src}
            size={!isDesktop ? 140 : 180}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            viewBox={`0 0 ${!isDesktop ? '140' : '180'} ${
              !isDesktop ? '140' : '180'
            }`}
            level="Q"
          />
          <img
            src={logoUrl}
            alt="qr ttf logo"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            width={!isDesktop ? '45px' : '55px'}
            height={!isDesktop ? '45px' : '55px'}
          />
        </>
      </Sheet>
    );
  };

  return (
    <Sheet
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: !isDesktop ? 140 : 180,
        width: !isDesktop ? 140 : 180,
        minHeight: !isDesktop ? 140 : 180,
        height: !isDesktop ? 140 : 180,
        borderRadius: 8,
        mb: !isDesktop ? 1.5 : 2,
        p: 2,
        mt: isDesktop ? '97.5px' : 0,
      }}
    >
      {renderImage()}
    </Sheet>
  );
};
