import { useState } from 'react';
import { TextField, Typography, TextFieldProps, Sheet } from '@mui/joy';
import { ReactComponent as ErrorIcon } from 'assets/icons/error-icon.svg';

export const Input = ({ type, error, required, ...rest }: TextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Sheet sx={{ position: 'relative' }}>
      <TextField
        type={showPassword ? 'text' : type}
        {...rest}
        endDecorator={
          type === 'password' ? (
            <Typography
              level="body4"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              role="button"
            >
              {showPassword ? 'Hide' : 'Show'}
            </Typography>
          ) : (
            rest.endDecorator
          )
        }
        error={error}
        required={required}
      />
      {error && (
        <ErrorIcon
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        />
      )}
    </Sheet>
  );
};
