import Sheet from '@mui/joy/Sheet';
import { Typography } from '@mui/joy';
import { AvatarRadioInputProps } from './types';
import { AvatarName } from '../AvatarName';
import { DateFormatted } from '../DateFormatted';

type RadioCircleProps = {
  checked: boolean;
  disabled: boolean;
};

const RadioCircle = ({ checked, disabled }: RadioCircleProps) => (
  <Sheet
    sx={(theme) => ({
      width: 20,
      height: 20,
      borderRadius: '50%',
      color: disabled
        ? theme.palette.common.darkGrey
        : checked
        ? theme.palette.common.mediumBlue
        : theme.palette.common.black,
      border: `2px solid currentColor`,
      backgroundColor: 'inherit',
      position: 'relative',
      '&:after': {
        content: '""',
        display: checked ? 'block' : 'none',
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    })}
  />
);

export const AvatarRadioInput = ({
  avatar,
  checked,
  onChange,
  disabled,
}: AvatarRadioInputProps) => (
  <Sheet
    variant="outlined"
    color="primary"
    sx={{
      height: 100,
      px: 2,
      borderColor: disabled
        ? 'common.extraLightGrey'
        : checked
        ? 'primary.solidBg'
        : 'common.lightGrey',
      borderRadius: '2px',
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      cursor: disabled ? 'not-allowed' : 'pointer',
      backgroundColor: disabled ? 'common.extraLightGrey' : 'common.white',
      my: 2,
    }}
    component="label"
    data-testid={disabled ? 'unavailable-avatar' : 'available-avatar'}
  >
    <RadioCircle checked={checked} disabled={disabled} />
    <div>
      <AvatarName name={avatar.name} disabled={disabled} />
      <DateFormatted date={avatar.timestamp} />
      {disabled && (
        <Typography level="body3" sx={{ color: 'common.red' }}>
          Avatar unavailable (processing failed)
        </Typography>
      )}
    </div>
    <input
      type="checkbox"
      checked={checked}
      onChange={() => onChange(avatar.id)}
      disabled={disabled}
      style={{ display: 'none' }}
      data-testid="avatar-radio-input"
    />
  </Sheet>
);
