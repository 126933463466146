import { Avatar, ApiKeyPermissions } from 'db/types';

type FilteredScans = {
  availableScans: Avatar[] | [];
  unavailableScans: Avatar[] | [];
};

type FilterScansParameters = {
  scans: Avatar[] | [];
  permissions: ApiKeyPermissions;
};

export function filterScans({
  permissions,
  scans,
}: FilterScansParameters): FilteredScans {
  const availableScans: Avatar[] = [];
  const unavailableScans: Avatar[] = [];
  if (!scans) {
    return { availableScans, unavailableScans };
  }

  scans.forEach((scan) => {
    const { pipelineStatus } = scan;
    if (pipelineStatus === 'APPROVED') {
      availableScans.push(scan);
    } else if (pipelineStatus === 'PROCESSING' && permissions.length === 0) {
      availableScans.push(scan);
    } else {
      unavailableScans.push(scan);
    }
  });

  return {
    availableScans,
    unavailableScans,
  };
}
