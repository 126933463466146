// src => https://github.com/firebase/firebase-js-sdk/blob/112e0d78173ded665c5ac2b03c8da564880da6b6/packages/firestore/src/util/misc.ts#L25

export const autoId = () => {
  // Alphanumeric characters
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  // The largest byte value that is a multiple of `char.length`.
  const maxMultiple = Math.floor(256 / chars.length) * chars.length;
  let id = '';
  const targetLength = 20;
  while (id.length < targetLength) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const bytes = randomBytes(40);
    for (let i = 0; i < bytes.length; ++i) {
      // Only accept values that are [0, maxMultiple), this ensures they can
      // be evenly mapped to indices of `chars` via a modulo operation.
      if (id.length < targetLength && bytes[i] < maxMultiple) {
        id += chars.charAt(bytes[i] % chars.length);
      }
    }
  }
  return id;
};

// 👇 coded by copilot 🤖
const randomBytes = (byteCount: number) => {
  const buffer = new Uint8Array(byteCount);
  for (let i = 0; i < byteCount; i++) {
    buffer[i] = Math.floor(Math.random() * 256);
  }
  return buffer;
};
