import { UserCredential, User } from 'firebase/auth';
import { ScanData } from 'db/types';
import { ErrorTemplate } from 'utils/getFirebaseAuthError';

// eslint-disable-next-line no-shadow
export enum MessageType {
  HANDSHAKE_REQUEST = 'HANDSHAKE_REQUEST',
  HANDSHAKE = 'HANDSHAKE',
  REQUEST_SCAN_DATA = 'REQUEST_SCAN_DATA',
  AVAILABLE_SCAN_ID = 'AVAILABLE_SCAN_ID',
  GET_SCAN_DATA = 'GET_SCAN_DATA',
  WIDGET_READY = 'WIDGET_READY',
  OPEN_WIDGET = 'OPEN_WIDGET',
  OPEN_WIDGET_RESPONSE = 'OPEN_WIDGET_RESPONSE',
  DIMENSIONS = 'DIMENSIONS',
  WIDGET_CLOSED = 'WIDGET_CLOSED',
}

export type Message = {
  type: MessageType;
  payload: {
    [key: string]: any;
  };
};

export interface MessagesApiContextState {
  sendMessage: (message: Message) => void;
  receivedMessages: Message[];
  sendScanData: (scanData: ScanData) => void;
}

export interface AuthContextState {
  currentUser: User | null;
  login: (
    email: string,
    password: string,
    rememberMe: boolean
  ) => Promise<UserCredential | undefined>;
  logout: () => Promise<void>;
  loading: boolean;
  error: ErrorTemplate;
}

export interface SharedScanDataContextState {
  selectedScanId: string | null;
  selectScan: (scanId: string) => void;
  shareScan: () => void;
  scanSharingLoading: boolean;
}
