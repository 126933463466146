import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  Outlet,
  useLocation,
  useNavigate,
  createBrowserRouter,
} from 'react-router-dom';
import { useSession, SessionContextProvider } from 'context/SessionProvider';
import { useAPIKeyDoc } from 'hooks/useAPIKeyDoc';
import { useAuth } from 'context/AuthContextProvider';
import { shareScanFromWidget } from 'db/base';
import { trackEvent } from 'utils/trackEvent';
import { SuspenseLoader } from 'components/Styles';
import { Typography, Sheet } from '@mui/joy';
import { Loader } from 'components/Loader';
import { updateDoc } from 'firebase/firestore';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { WidgetButton } from 'components/WidgetButton';
import { Modal } from 'components/Modal/Modal.component';
import { ModalContextProvider } from 'components/Modal/Modal.context';
import { ErrorFallback } from 'components/Errors/ErrorFallback';
import { MessagesApiProvider } from 'context/MessagesApiProvider';

import LandingPage from 'pages/Landing';
import Scans from 'pages/Scans';
import Login from 'pages/Login';
import ScanStartedPage from 'pages/Started';
import ScanSentPage from 'pages/Sent';
import ScanSubmittingPage from 'pages/Submitting';
import { Protected } from './Protected';

export const routes = createBrowserRouter([
  {
    path: '/',
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    element: <InnerProviders />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'started',
        element: <ScanStartedPage />,
      },
      {
        path: 'sent',
        element: <ScanSentPage />,
      },
      {
        path: 'submitting',
        element: <ScanSubmittingPage />,
      },
      {
        element: <Protected />,
        children: [
          {
            path: 'create',
            element: <LandingPage />,
          },
          {
            path: 'scans',
            element: <Scans />,
          },
        ],
      },
    ],
  },
]);

function AuthLoader() {
  return (
    <Sheet
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '32px',
        height: '100%',
        minHeight: 521,
      }}
    >
      <Typography level="h1">Logging you in</Typography>
      <Loader />
    </Sheet>
  );
}

export function RouterOutlet({
  searchParams,
}: {
  searchParams: URLSearchParams;
}) {
  const { loading, currentUser } = useAuth();
  const prevUser = useRef(currentUser);
  const queryClient = useQueryClient();
  const { data, isIdle, isLoading: apiKeyLoading } = useAPIKeyDoc();
  const { loading: sessionLoading, data: sessionData } = useSession();
  const location = useLocation();
  const navigate = useNavigate();

  const handleRouteMatch = () => {
    if (searchParams.get('apiKey')) {
      // add the same search params to the url
      const url = new URL(window.location.href);
      for (const [key, value] of searchParams) {
        url.searchParams.set(key, value);
      }
      window.history.replaceState({}, '', url.toString());
    }
  };

  useEffect(() => {
    handleRouteMatch();
  }, [location]);

  useEffect(() => {
    async function updateSessionData() {
      const { activeScan, clientId } = { ...sessionData };
      if (activeScan && clientId) {
        const { progress } = { ...sessionData };
        if (progress === 'UPLOADED') {
          if (sessionData?.ref && !sessionData?.data) {
            trackEvent('widget_share_scan', {
              scanId: activeScan.id,
              userId: activeScan.userId,
              clientId,
            });
            const res = await shareScanFromWidget({
              scanId: activeScan.id,
              apiKey: data?.apiKey ?? '',
              userId: activeScan.userId,
            });
            // update the session data if it is not already updated
            await updateDoc(sessionData.ref, {
              data: {
                ...res.data,
                id: activeScan.id,
              },
            });
          }
        }
      }
    }
    if (sessionData?.data) {
      navigate('/sent');
    } else if (sessionData?.progress === 'STARTED') {
      // if there in between states eg: [PENDING] then it should be add here
      navigate('/started');
    } else if (sessionData?.progress === 'SUBMITTING') {
      navigate('/submitting');
    }
    updateSessionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionData]);

  useEffect(() => {
    if (currentUser && location.pathname === '/') {
      navigate('/scans');
    }
    // detect if the user has logged out
    if (!currentUser && prevUser.current) {
      queryClient.clear();
    }
    prevUser.current = currentUser;
  }, [currentUser, location.pathname, navigate, queryClient]);

  if (sessionLoading || apiKeyLoading) return <SuspenseLoader />;

  if (loading) return <AuthLoader />;

  if (!data || isIdle)
    return (
      <Typography level="h3" color="danger" variant="outlined">
        No API key found
      </Typography>
    );

  return <Outlet />;
}

function InnerProviders() {
  const [searchParams, setSearchParams] = useState(
    new URLSearchParams(window.location.search)
  );

  useEffect(() => {
    const handleSearchChange = () => {
      setSearchParams(new URLSearchParams(window.location.search));
    };

    window.addEventListener('search', handleSearchChange);

    return () => {
      window.removeEventListener('search', handleSearchChange);
    };
  }, [searchParams]);
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <SessionContextProvider>
        <MessagesApiProvider>
          <WidgetButton />
          <ModalContextProvider>
            <Modal>
              <RouterOutlet searchParams={searchParams} />
            </Modal>
          </ModalContextProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </MessagesApiProvider>
      </SessionContextProvider>
    </ErrorBoundary>
  );
}
