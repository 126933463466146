import { Typography, Sheet, styled } from '@mui/joy';
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import { getAspectRatio } from 'utils/getAspectRatio';
import { LogoProps, LogoIconProps } from './Logo.types';

const StyledLogoIcon = styled(LogoIcon)<LogoIconProps>(
  ({ theme, ...props }) => ({
    width: props.size.width,
    height: props.size.height,
    fill: props.color,
    '& path': {
      fill: props.color,
    },
  })
);

export function Logo({
  /** if false the logo will be the SVG Icon only */
  withText = true,
  /** color of the logo icon and text */
  color = '#000',
  /** size of the logo icon and text */
  size,
  /** direction of the logo icon and text can be horizontal or vertical */
  direction = 'horizontal',
}: LogoProps) {
  if (!withText) return <LogoIcon width="100%" height="100%" />;
  const fontSize =
    size === 'sm' ? '10.75px' : size === 'md' ? '15.75px' : '18.45px';
  const iconSize = size === 'sm' ? 11.98 : size === 'md' ? 16.94 : 53.7;
  return (
    <Sheet
      sx={{
        display: 'flex',
        alignItems: direction === 'horizontal' ? 'flex-start' : 'center',
        justifyContent: 'center',
        flexDirection: direction === 'horizontal' ? 'row' : 'column',
        gap: size === 'sm' ? '4px' : size === 'md' ? '5px' : '6px',
      }}
    >
      <StyledLogoIcon
        color={color}
        size={{
          width: `${getAspectRatio(iconSize, 53.7 / 38.47).width}px`,
          height: `${getAspectRatio(iconSize, 53.7 / 38.47).height}px`,
        }}
      />
      <Typography
        level="h3"
        sx={{
          fontFamily: "'Josefin Sans', sans-serif",
          color,
          fontSize,
          fontWeight: 700,
          textTransform: 'uppercase',
          lineHeight:
            size === 'sm' ? '12px' : size === 'md' ? `17px` : '30.75px',
          transition: 'color 0.2s ease-in-out',
        }}
      >
        True<span style={{ fontWeight: 300, letterSpacing: '0.01em' }}>To</span>
        Form
      </Typography>
    </Sheet>
  );
}
