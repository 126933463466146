type RedirectUser = {
  apiKey: string;
  clientId: string;
  baseUrl?: string;
  sessionId: string;
  redirectUrl: string;
};

export function getRedirectUrl({
  baseUrl = window.location.origin,
  apiKey,
  clientId,
  sessionId,
  redirectUrl,
}: RedirectUser) {
  const url = new URL(baseUrl);
  url.searchParams.set('apiKey', apiKey);
  url.searchParams.set('clientId', clientId);
  url.searchParams.set('redirectUrl', redirectUrl);
  url.searchParams.set('sessionId', sessionId);
  return url.toString();
}

export function redirectUser({
  baseUrl = window.location.origin,
  apiKey,
  clientId,
  sessionId,
  redirectUrl,
}: RedirectUser) {
  const isIframe = window.self !== window.top;
  if (!isIframe) return;
  // redirect to the widget page
  if (window.top) {
    const urlStr = getRedirectUrl({
      baseUrl,
      apiKey,
      clientId,
      sessionId,
      redirectUrl,
    });
    const a = document.createElement('a');
    a.href = urlStr;
    a.target = '_blank';
    a.rel = 'opener';
    a.click();
  }
}
