import { Sheet, Button } from '@mui/joy';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { fetchClientData } from 'hooks/useClientData';
import { useAPIKeyDoc } from 'hooks/useAPIKeyDoc';
import { Loader } from '../Loader';
import { useModalContext } from '../Modal/Modal.context';

type Props = {
  scanId: string | null;
  onClick: () => void;
  loading: boolean;
  isRefetching: boolean;
};

export const Drawer = ({ scanId, onClick, loading, isRefetching }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: apiKeyDoc } = useAPIKeyDoc();
  const { dimensions } = useModalContext();
  return (
    // the positioning of the drawer considering the padding of the container Component
    <Sheet
      sx={{
        position: 'absolute',
        bottom: 32,
        left: -32,
        boxShadow: `0px -12px 12px -19px rgba(14, 26, 68, 0.03)`,
      }}
      data-testid="drawer"
    >
      <Sheet
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          py: 2,
          px: 4,
          boxShadow: `0px -12px 12px -19px rgba(14, 26, 68, 0.03)`,
          position: 'fixed',
          width: dimensions.width,
          maxWidth: 'calc(100% - 48px)', // 48px is the margin of the container
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Button
            fullWidth
            variant="solid"
            onClick={onClick}
            disabled={!scanId || loading || isRefetching}
          >
            Send
          </Button>
        )}
        <Button
          color="primary"
          variant="plain"
          onClick={() => {
            navigate('/create');
          }}
          onMouseEnter={() => {
            queryClient.prefetchQuery('clientData', () =>
              fetchClientData(apiKeyDoc?.clientId)
            );
          }}
          disabled={loading}
        >
          Create a new avatar
        </Button>
      </Sheet>
    </Sheet>
  );
};
