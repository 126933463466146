import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import {
  query,
  where,
  collection,
  getDocs,
  CollectionReference,
} from 'firebase/firestore';
import { db } from 'db/base';
import { OrgAPIDoc } from 'db/types';

async function validateAPIKey({
  apiKey,
  clientId,
  origin,
}: {
  apiKey: string;
  clientId: string;
  origin: string;
}) {
  const q = query(
    collection(db, 'api_keys') as CollectionReference<OrgAPIDoc>,
    where('clientId', '==', clientId),
    where('origins', 'array-contains', origin)
  );
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    return Promise.reject(new Error('Invalid API key'));
  }
  if (querySnapshot.docs.length > 1) {
    return Promise.reject(new Error('Multiple API keys found'));
  }
  const docId = querySnapshot.docs[0].id;
  if (docId !== apiKey) {
    return Promise.reject(new Error('Invalid API key'));
  }
  return Promise.resolve(querySnapshot.docs[0].data());
}

async function handleValidateAPIKey(
  apiKey: string | null,
  clientId: string | null,
  origin: string
) {
  if (apiKey && clientId) {
    const data = await validateAPIKey({ apiKey, clientId, origin });
    if (data) {
      if (data.origins.includes(origin)) {
        return {
          ...data,
          apiKey,
          origin,
        };
      }
      throw new Error('Invalid origin');
    }
  }
  return Promise.reject(new Error('API key or client ID is missing'));
}

export function useAPIKeyDoc() {
  const [apiDocKeys, setApiDocKeys] = useState<{
    apiKey: string | null;
    clientId: string | null;
    origin: string;
  }>({
    apiKey: null,
    clientId: null,
    origin: '',
  });
  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data.type === 'HANDSHAKE') {
        const { payload } = event.data;
        setApiDocKeys({
          apiKey: payload.apiKey,
          clientId: payload.clientId,
          origin: event.origin,
        });
      }
    });
    // incase of the the window isn't an IFRAME and the handshake event is never sent
    // then get the api key and client id from the URL
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      const apiKeyValue = urlParams.get('apiKey');
      const clientIdValue = urlParams.get('clientId');
      const redirectUrl = urlParams.get('redirectUrl');
      const originOfRedirectUrl = redirectUrl
        ? new URL(redirectUrl).origin
        : '';
      setApiDocKeys({
        apiKey: apiKeyValue,
        clientId: clientIdValue,
        origin: originOfRedirectUrl,
      });
    }
    return () => {
      window.removeEventListener('message', () => {});
    };
  }, []);

  const { apiKey, clientId, origin } = apiDocKeys;
  return useQuery(
    'apiKeyDoc',
    () => handleValidateAPIKey(apiKey, clientId, origin),
    {
      enabled: Boolean(apiKey && clientId && origin),
      staleTime: 1000 * 60 * 60 * 24, // 24 hours
      refetchOnWindowFocus: false,
    }
  );
}
