import {
  CollectionReference,
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { db, storage } from 'db/base';
import { getDownloadURL, ref } from 'firebase/storage';

import { ClientData } from 'db/types';
import { useQuery } from 'react-query';
import { useAPIKeyDoc } from './useAPIKeyDoc';
import { THREE_MINUTES } from './constants';

export const fetchClientData = async (clientId: string | undefined) => {
  if (!clientId) throw new Error('Client ID is missing');
  const q = query(
    collection(db, 'clientIds') as CollectionReference<ClientData>,
    where('clientId', '==', clientId)
  );
  const querySnapshot = await getDocs(q);
  const data = querySnapshot.docs?.[0].data();
  const qrImageRef = ref(storage, `org_profiles/${data?.clientId}/qrCode`);
  const qrImageURL = await getDownloadURL(qrImageRef);
  return { ...data, qrImageURL };
};

export function useClientData() {
  const { data } = useAPIKeyDoc();
  const clientId = data?.clientId;
  return useQuery('clientData', () => fetchClientData(clientId), {
    enabled: !!clientId,
    staleTime: THREE_MINUTES,
  });
}
