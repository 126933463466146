import { Typography } from '@mui/joy';
import { isInsideIframe } from 'utils/isInsideIframe';
import { SessionContextProvider } from 'context/SessionProvider';
import { MessagesApiProvider } from 'context/MessagesApiProvider';
import { Modal } from '../Modal/Modal.component';
import { ModalContextProvider } from '../Modal/Modal.context';
import { SessionExpired } from '../templates/SessionExpired';
import { WidgetButton } from '../WidgetButton';

export function ErrorFallback({ error }: { error: Error }) {
  console.log(error.message);
  if (error.message.includes('Session has expired'))
    return (
      <ModalContextProvider>
        <Modal>
          <SessionExpired />
        </Modal>
      </ModalContextProvider>
    );
  // don't display error if we are inside iframe (WidgetButton host view)
  if (error.message.includes('expired') && isInsideIframe())
    return (
      <SessionContextProvider>
        <MessagesApiProvider>
          <WidgetButton />
        </MessagesApiProvider>
      </SessionContextProvider>
    );
  return (
    <Typography level="h2" color="danger">
      {error.message}
    </Typography>
  );
}
