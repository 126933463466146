import { Sheet, Typography, Button, Checkbox } from '@mui/joy';
import { useAPIKeyDoc } from 'hooks/useAPIKeyDoc';
import { useAuth } from 'context/AuthContextProvider';
import { Input } from 'components/Input';
import { Container } from 'components/Styles';
import { Navigate } from 'react-router-dom';
import { BackBar } from 'components/BackBar';
import { Form } from './components/Form';

function LoginPage() {
  const { isSuccess } = useAPIKeyDoc();
  const { login, error, currentUser } = useAuth();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    const email = formData.get('email') as string;
    const password = formData.get('password') as string;
    const checkbox = document.getElementById('checkbox') as HTMLInputElement;
    login(email, password, checkbox.checked);
  };

  if (currentUser) {
    return <Navigate to="/scans" />;
  }

  if (!isSuccess) {
    return (
      <Container>
        <Typography level="h3" color="danger">
          Invalid API key and/or clientId!
        </Typography>
      </Container>
    );
  }

  return (
    <>
      <BackBar header="Log in" />
      <Container data-testid="login-page" sx={{ pt: 0, minHeight: 508 }}>
        <Typography level="body1" sx={{ color: 'text.tertiary', pb: 3 }}>
          Log in to send avatars you’ve already created, or to save new avatars
          to your account.
        </Typography>
        <Sheet>
          <Form onSubmit={handleSubmit} error={error}>
            <Input
              type="email"
              label="Email"
              name="email"
              id="email"
              sx={{ mb: 1.5 }}
              error={error?.element === 'email'}
              required
            />
            <Input
              type="password"
              label="Password"
              name="password"
              id="password"
              error={error?.element === 'password'}
              required
            />
            <Sheet
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mt: 5,
              }}
            >
              <Checkbox
                name="checkbox"
                id="checkbox"
                label="Stay logged in"
                defaultChecked
              />
              <Typography
                component="a"
                level="body1"
                href={`${process.env.REACT_APP_TTF_URL}/passwordReset`}
                target="_blank"
                sx={(theme) => ({
                  textDecoration: 'underline',
                  color: theme.palette.primary.solidBg,
                })}
              >
                Forgot password
              </Typography>
            </Sheet>
            <Button
              fullWidth
              color="primary"
              variant="solid"
              type="submit"
              sx={{ mt: '134px' }}
            >
              Log in
            </Button>
          </Form>
        </Sheet>
      </Container>
    </>
  );
}

export default LoginPage;
