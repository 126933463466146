import {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { ScanData } from 'db/types';
import { useAPIKeyDoc } from 'hooks/useAPIKeyDoc';
import { Typography } from '@mui/joy';
import { trackEvent } from 'utils/trackEvent';
import { autoId } from 'utils/autoId';
import { getRedirectUrl } from 'utils/redirectUser';
import { isWidgetIframe } from 'utils/isWidgetIframe';
import { Message, MessagesApiContextState, MessageType } from './types';
import { useSession } from './SessionProvider';

const MessagesApiContext = createContext({} as MessagesApiContextState);

function MessagesApiProvider(props: { children: React.ReactNode }) {
  const [receivedMessages, setReceivedMessages] = useState<Message[]>([]);
  const source = useRef<MessageEventSource | null>(null);
  const hostOrigin = useRef<string | null>(null);
  const { data: apiKeyDoc, isError } = useAPIKeyDoc();
  const { origins } = { ...apiKeyDoc };
  const { data: session, createNewSession } = useSession();
  const [openRequests, setOpenRequests] = useState<Message[]>([]);

  const sendMessage = (message: Message) => {
    trackEvent('widget_message_sent', {
      origin: hostOrigin.current || '',
      clientId: apiKeyDoc?.clientId || '',
    });
    // send message to host window
    source.current?.postMessage(message, {
      targetOrigin: hostOrigin.current || '',
    });
  };

  useEffect(() => {
    // send the initial message to the host window
    if (window.top) {
      window.top.postMessage(
        {
          type: MessageType.HANDSHAKE_REQUEST,
          payload: {},
        },
        '*'
      );
    }
    // add the source and origin to the state
    window.addEventListener('message', (event) => {
      if (event.data.type === 'HANDSHAKE') {
        source.current = event.source;
        hostOrigin.current = event.origin;
      }
      if (event.data.type === MessageType.OPEN_WIDGET) {
        setOpenRequests((prevOpenRequests) => [
          ...prevOpenRequests,
          event.data,
        ]);
      }
      if (apiKeyDoc?.clientId) {
        trackEvent('widget_message_received', {
          origin: event.origin,
          clientId: apiKeyDoc.clientId,
          type: event.data.type,
          data: event.data,
        });
      }
    });
  }, []);

  useEffect(() => {
    function openNewSession() {
      const sessionId = autoId();
      trackEvent('widget_new_session', {
        sessionId,
        clientId: apiKeyDoc?.clientId ?? '',
      });
      createNewSession(sessionId);
      if (apiKeyDoc && !isWidgetIframe()) {
        const redirectUrl = getRedirectUrl({
          sessionId,
          apiKey: apiKeyDoc.apiKey,
          clientId: apiKeyDoc.clientId,
          redirectUrl: apiKeyDoc.origin,
        });
        // send the redirect url to the host window
        sendMessage({
          type: MessageType.OPEN_WIDGET_RESPONSE,
          payload: {
            url: redirectUrl,
          },
        });
      }
    }
    if (!openRequests.length) {
      return;
    }
    // send the open widget response to the host window
    openNewSession();
  }, [openRequests]);

  useEffect(() => {
    // set up listener for messages from host web app
    window.addEventListener('message', (event) => {
      const { origin, data } = event;
      if (origins?.includes(origin)) {
        setReceivedMessages((prevMessages) => [...prevMessages, data]);
      }
    });
    return () => {
      window.removeEventListener('message', () => {});
    };
  }, [origins]);

  useEffect(() => {
    const { data } = { ...session };
    const hasExpeired = session && session.expiresAt.toMillis() < Date.now();
    if (data && source.current && !hasExpeired) {
      sendScanData(data);
    }
  }, [session?.data]);

  const sendScanData = (scanData: ScanData) => {
    sendMessage({ type: MessageType.GET_SCAN_DATA, payload: scanData });
  };

  const value = useMemo(
    () => ({
      sendMessage,
      receivedMessages,
      sendScanData,
    }),
    [receivedMessages]
  );

  if (isError) {
    return (
      <Typography level="h3" color="danger">
        Invalid API key and/or clientId!
      </Typography>
    );
  }

  return <MessagesApiContext.Provider {...props} value={value} />;
}

const useMessagesApi = () => useContext(MessagesApiContext);

export { MessagesApiProvider, useMessagesApi };
