import { Button, Sheet, Typography } from '@mui/joy';
import { useMediaQuery } from 'react-responsive';
import { useModalContext } from 'components/Modal/Modal.context';
import { useAPIKeyDoc } from 'hooks/useAPIKeyDoc';
import { BREAK_POINTS } from '../../joyTheme';

const CloseConfirmation = () => {
  const isMobile = useMediaQuery({ query: BREAK_POINTS.MOBILE.query });
  const { closePage, hideCloseConfirmation, dimensions } = useModalContext();
  const { data: apiDocData } = useAPIKeyDoc();
  const { orgName = '', clientId = '' } = { ...apiDocData };

  return (
    <Sheet
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        px: 3,
        height: dimensions?.height ? dimensions.height - 40 : 'auto',
      }}
      data-testid="started-view"
    >
      <Typography
        level="display2"
        sx={{
          mb: 1,
          fontWeight: 600,
          fontSize: isMobile ? '20px' : '24px',
          lineHeight: '32px',
          letterSpacing: '0.24px',
          textAlign: 'center',
        }}
      >
        Cancel adding measurements?
      </Typography>
      <Typography
        level="body1"
        sx={{ color: 'text.secondary', textAlign: 'center', mt: 0.5 }}
      >
        Upon closing this page, the connection for adding your measurements from
        the TrueToForm scan app to{' '}
        <Typography
          level="body1"
          sx={{ color: 'text.primary', fontWeight: 700 }}
          component="span"
        >
          {orgName || clientId}
        </Typography>{' '}
        will be lost.
      </Typography>
      <Sheet
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '16px',
          mt: 3,
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <Button variant="outlined" onClick={closePage}>
          Yes, cancel
        </Button>
        <Button variant="solid" onClick={hideCloseConfirmation}>
          No, keep going
        </Button>
      </Sheet>
    </Sheet>
  );
};

export default CloseConfirmation;
