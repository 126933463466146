import { Sheet, Typography, Button } from '@mui/joy';
import { Container } from 'components/Styles';
import { useAPIKeyDoc } from 'hooks/useAPIKeyDoc';
import { SharedScanProvider } from 'context/SharedScan';
import { useQueryClient } from 'react-query';
import { ReactComponent as ResetIcon } from 'assets/icons/reset-icon.svg';
import ScansMenu from './components/ScansMenu';

function Scans() {
  const { data: apiKeyDoc } = useAPIKeyDoc();
  const queryClient = useQueryClient();

  if (!apiKeyDoc) return null;

  return (
    <SharedScanProvider>
      <Container>
        <Sheet
          sx={{
            minHeight: 521,
          }}
        >
          <Typography level="h1">Choose an avatar</Typography>
          <Typography level="body3" sx={{ mb: 2 }}>
            Avatars created on the TrueToForm scan app show here
          </Typography>

          <Sheet
            sx={{
              borderBottom: `1px solid var(--ttf-palette-primary-outlinedBorder)`,
              pb: '4px',
            }}
          >
            <Sheet
              component="nav"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Button
                variant="plain"
                color="primary"
                startDecorator={<ResetIcon />}
                onClick={() => {
                  queryClient.refetchQueries('scannerScans');
                }}
                sx={{
                  mr: 0,
                  p: 0,
                  width: 110,
                  minWidth: 110,
                }}
              >
                Refresh List
              </Button>
            </Sheet>
          </Sheet>
          <ScansMenu />
        </Sheet>
      </Container>
    </SharedScanProvider>
  );
}

export default Scans;
