import {
  where,
  query,
  collection,
  getDocs,
  orderBy,
  CollectionReference,
} from 'firebase/firestore';
import { useQuery } from 'react-query';
import { db } from 'db/base';
import { ScanDoc, Avatar } from 'db/types';
import { useAuth } from 'context/AuthContextProvider';
import { THREE_MINUTES } from './constants';

const fetchScannerScans = async (uid: string | undefined) => {
  if (!uid) throw new Error('User ID is missing');
  const q = query(
    collection(db, 'scans') as CollectionReference<ScanDoc>,
    where('userId', '==', uid),
    orderBy('timestamp', 'desc')
  );
  const querySnapshot = await getDocs(q);
  const data = querySnapshot.docs.map(
    (doc) => ({ ...doc.data(), id: doc.id } as Avatar)
  );
  return data;
};

export function useScannerScans() {
  const { currentUser } = useAuth();
  return useQuery('scannerScans', () => fetchScannerScans(currentUser?.uid), {
    enabled: !!currentUser?.uid,
    staleTime: THREE_MINUTES,
  });
}
