import { createContext, useState, useEffect, useContext, useMemo } from 'react';
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  User,
  setPersistence,
  browserSessionPersistence,
} from 'firebase/auth';
import { auth } from 'db/base';
import {
  getFirebaseAuthError,
  ErrorTemplate,
} from 'utils/getFirebaseAuthError';
import { trackEvent } from 'utils/trackEvent';
import { AuthContextState } from './types';

const AuthContext = createContext({} as AuthContextState);
AuthContext.displayName = 'AuthContext';

let clientId = '';
if (window.location.search) {
  const urlParams = new URLSearchParams(window.location.search);
  clientId = urlParams.get('clientId') ?? '';
}

function AuthContextProvider(props: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorTemplate>({
    message: '',
    element: '',
  });
  const [loginPressed, setLoginPressed] = useState(false);

  const login = async (
    email: string,
    password: string,
    rememberMe: boolean
  ) => {
    setLoading(true);
    try {
      setLoginPressed(true);
      if (rememberMe) {
        const user = await signInWithEmailAndPassword(auth, email, password);
        return user;
      }
      await setPersistence(auth, browserSessionPersistence);
      const userPersisted = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      return userPersisted;
    } catch (err) {
      console.log(err);
      trackEvent('widget_login_error', { clientId });
      // @ts-ignore
      setError(getFirebaseAuthError(err));
      setLoading(false);
    }
  };

  const logout = async () => {
    await signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoading(false);
      setError({ message: '', element: '' });
      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (loginPressed && currentUser) {
      // then the current user has logged in manually
      trackEvent('widget_login', { clientId });
      setLoginPressed(false);
    }
  }, [loginPressed, currentUser]);

  const value = useMemo(
    () => ({
      currentUser,
      login,
      logout,
      loading,
      error,
    }),
    [currentUser, loading, error]
  );
  return <AuthContext.Provider value={value} {...props} />;
}

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthContextProvider');
  }
  return context;
};

export { AuthContextProvider, useAuth };
