import Typography from '@mui/joy/Typography';
import { ScanDoc } from 'db/types';

type Props = {
  name: ScanDoc['name'];
  disabled: boolean;
};

export const AvatarName = ({ name, disabled }: Props) => (
  <Typography
    level="h2"
    sx={{
      color: disabled ? 'text.secondary' : 'text.primary',
    }}
  >
    {name ?? 'No Name'}
  </Typography>
);
