import { Sheet, Typography } from '@mui/joy';
import { ReactComponent as SentIcon } from 'assets/icons/sent-icon.svg';
import { useAPIKeyDoc } from 'hooks/useAPIKeyDoc';
import { useSession } from 'context/SessionProvider';
import { Navigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { BREAK_POINTS } from '../../joyTheme';

const MeasurementsSent = () => {
  const { data: apiKey } = useAPIKeyDoc();
  const { orgName = '', clientId = '' } = apiKey || {};
  const { data } = useSession();
  const isMobile = useMediaQuery({ query: BREAK_POINTS.MOBILE.query });

  if (!data?.data) return <Navigate to="/" />;

  return (
    <Sheet
      sx={{
        mt: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        px: 3,
      }}
      data-testid="sent-view"
    >
      <SentIcon />
      <Typography
        level="display2"
        sx={{
          mt: 3,
          mb: 1,
          fontWeight: 700,
          fontSize: '36px',
          lineHeight: '42px',
          textAlign: 'center',
        }}
      >
        Measurements sent!
      </Typography>
      <Typography
        level="body1"
        sx={{
          color: 'text.secondary',
          textAlign: 'center',
          '& b': {
            color: 'common.extraDarkGrey',
          },
        }}
      >
        Your avatar and body measurements have been sent {!isMobile && <br />}{' '}
        to: <b>{orgName || clientId}</b>
      </Typography>
      <Typography level="h2" sx={{ mt: 3 }}>
        You may close this tab
      </Typography>
    </Sheet>
  );
};

export default MeasurementsSent;
