import { NoScans } from 'components/templates/NoScans';
import { AvatarRadioInput } from 'components/AvatarRadioInput';
import { Loader } from 'components/Loader';
import { useScannerScans } from 'hooks/useScannerScans';
import { useSharedScanContext } from 'context/SharedScan';
import { Drawer } from 'components/Drawer';
import { Sheet, Typography } from '@mui/joy';
import { useAPIKeyDoc } from 'hooks/useAPIKeyDoc';
import { Avatar } from 'db/types';
import { ScansContainer } from './ScansContainer';
import { LoaderContainer } from './LoaderContainer';

const ScansMenu = () => {
  const {
    data: scans,
    isLoading: scansLoading,
    isRefetching,
  } = useScannerScans();
  const { selectedScanId, selectScan, shareScan, scanSharingLoading } =
    useSharedScanContext();
  const { data: apiKey } = useAPIKeyDoc();

  const scanIsDisabled = (scan: Avatar) => {
    const permissions = apiKey?.permissions;
    const { pipelineStatus } = scan;
    if (pipelineStatus === 'APPROVED') return false;
    if (pipelineStatus === 'PROCESSING' && permissions?.length === 0)
      return false;
    return true;
  };

  if (scansLoading)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );

  if (!scans?.length) return <NoScans />;

  return (
    <ScansContainer isRefetching={isRefetching} testId="scans-menu">
      {isRefetching ? (
        <LoaderContainer>
          <Sheet
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Loader />
            <Typography level="h2">Refreshing avatar list</Typography>
          </Sheet>
        </LoaderContainer>
      ) : (
        <>
          {scans.map((scan) => (
            <AvatarRadioInput
              key={scan.id}
              avatar={scan}
              checked={selectedScanId ? selectedScanId === scan.id : false}
              disabled={scanIsDisabled(scan)}
              onChange={() => {
                selectScan(scan.id);
              }}
            />
          ))}
        </>
      )}
      <Drawer
        scanId={selectedScanId}
        onClick={shareScan}
        loading={scanSharingLoading}
        isRefetching={isRefetching}
      />
    </ScansContainer>
  );
};

export default ScansMenu;
