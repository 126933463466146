import { useAuth } from 'context/AuthContextProvider';
import { Navigate, Outlet } from 'react-router-dom';

export const Protected = () => {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return <Navigate to="/" />;
  }

  // if routes are public redirect to home
  return <Outlet />;
};
