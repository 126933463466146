/* eslint-disable @typescript-eslint/no-unused-vars */
import { extendTheme } from '@mui/joy/styles';

const MEDIUM_BLUE = '#066FF9';
const LIGHT_BLUE = '#5398FF';
const DARK_BLUE = '#1963F1';
const MEDIUM_GREY = '#8C99C8';
const LIGHT_GREY = '#D9E4FE';
const EXTRA_LIGHT_GREY = '#F0F2FA';
const DARK_GREY = '#8A8A98';
const EXTRA_DARK_GREY = '#454550';
const WHITE = '#ffffff';
const BLACK = '#000000';
const LIGHT_PINK = '#FF627E';
const LIGHT_GREEN = '#81C083';
const GREEN = '#00A931';
const LIGHT_RED = '#FF7C94';
const RED = '#FF627E';
const DARK_RED = '#D93B57';
const GREY = '#7C7C89';
const YELLOW = '#F2BD35';

declare module '@mui/joy/styles/types/colorSystem' {
  interface PaletteCommon {
    black: string;
    white: string;
    grey: string;
    darkGrey: string;
    extraDarkGrey: string;
    lightGrey: string;
    extraLightGrey: string;
    mediumGrey: string;
    mediumBlue: string;
    lightBlue: string;
    darkBlue: string;
    lightPink: string;
    lightGreen: string;
    green: string;
    lightRed: string;
    red: string;
    darkRed: string;
    yellow: string;
  }
}

export const BREAK_POINTS = {
  MOBILE: {
    query: '(max-width: 600px)',
    value: 600,
  },
  TABLET: {
    query: '(min-width: 600px) and (max-width: 1024px)',
    value: 1024,
  },
  DESKTOP: {
    query: '(min-width: 1000px)',
    value: 1000,
  },
};

export const joyTheme = extendTheme({
  cssVarPrefix: 'ttf',
  colorSchemes: {
    light: {
      palette: {
        primary: {
          outlinedColor: MEDIUM_BLUE,
          outlinedBg: 'transparent',
          outlinedBorder: LIGHT_GREY,
          outlinedHoverColor: LIGHT_BLUE,
          outlinedHoverBg: 'transparent',
          outlinedHoverBorder: LIGHT_GREY,
          outlinedActiveColor: DARK_BLUE,
          outlinedActiveBg: 'transparent',
          outlinedActiveBorder: LIGHT_GREY,
          outlinedDisabledColor: MEDIUM_GREY,
          outlinedDisabledBg: 'transparent',
          outlinedDisabledBorder: LIGHT_GREY,
          solidColor: WHITE,
          solidBg: MEDIUM_BLUE,
          solidBorder: MEDIUM_BLUE,
          solidHoverColor: WHITE,
          solidHoverBg: LIGHT_BLUE,
          solidHoverBorder: LIGHT_BLUE,
          solidActiveColor: WHITE,
          solidActiveBg: DARK_BLUE,
          solidActiveBorder: DARK_BLUE,
          solidDisabledColor: MEDIUM_GREY,
          solidDisabledBg: LIGHT_GREY,
          solidDisabledBorder: LIGHT_GREY,
          plainColor: MEDIUM_BLUE,
          plainBg: 'transparent',
          plainBorder: 'transparent',
          plainHoverColor: LIGHT_BLUE,
          plainHoverBg: 'transparent',
          plainHoverBorder: 'transparent',
          plainActiveColor: DARK_BLUE,
          plainActiveBg: 'transparent',
          plainActiveBorder: 'transparent',
          plainDisabledColor: MEDIUM_GREY,
          plainDisabledBg: 'transparent',
          plainDisabledBorder: 'transparent',
          softBg: EXTRA_LIGHT_GREY,
          softBorder: EXTRA_LIGHT_GREY,
          softColor: EXTRA_LIGHT_GREY,
          softActiveBg: EXTRA_LIGHT_GREY,
          softActiveBorder: EXTRA_LIGHT_GREY,
          softActiveColor: EXTRA_DARK_GREY,
          softHoverBg: EXTRA_LIGHT_GREY,
          softHoverBorder: EXTRA_LIGHT_GREY,
          softHoverColor: EXTRA_DARK_GREY,
        },
        text: {
          primary: BLACK,
          secondary: DARK_GREY,
          tertiary: GREY,
        },
        common: {
          black: BLACK,
          white: WHITE,
          grey: GREY,
          darkGrey: DARK_GREY,
          extraDarkGrey: EXTRA_DARK_GREY,
          lightGrey: LIGHT_GREY,
          extraLightGrey: EXTRA_LIGHT_GREY,
          mediumGrey: MEDIUM_GREY,
          mediumBlue: MEDIUM_BLUE,
          lightBlue: LIGHT_BLUE,
          darkBlue: DARK_BLUE,
          lightPink: LIGHT_PINK,
          lightGreen: LIGHT_GREEN,
          green: GREEN,
          lightRed: LIGHT_RED,
          red: RED,
          darkRed: DARK_RED,
          yellow: YELLOW,
        },
        divider: LIGHT_GREY,
        danger: {
          outlinedColor: RED,
          outlinedBg: 'transparent',
          outlinedBorder: LIGHT_GREY,
          outlinedHoverColor: LIGHT_RED,
          outlinedHoverBg: 'transparent',
          outlinedHoverBorder: LIGHT_GREY,
          outlinedActiveColor: DARK_RED,
          outlinedActiveBg: 'transparent',
          outlinedActiveBorder: LIGHT_GREY,
          solidColor: WHITE,
          solidBg: RED,
          solidBorder: RED,
          solidHoverColor: WHITE,
          solidHoverBg: LIGHT_RED,
          solidActiveColor: WHITE,
          solidActiveBg: DARK_RED,
          softBg: LIGHT_RED,
          softColor: DARK_RED,
          overrideTextPrimary: BLACK,
          overrideTextSecondary: DARK_GREY,
          overrideTextTertiary: GREY,
        },
      },
    },
  },
  shadow: {
    sm: '0px 50px 60px -19px rgba(2, 4, 74, 0.03)',
    md: '0px 50px 60px -19px rgba(14, 26, 68, 0.05)',
    lg: '0px 8px 24px rgba(77, 85, 125, 0.08)',
    xl: '0px 10px 24px -8px rgba(10, 36, 128, 0.17)',
  },
  radius: {
    sm: '4px',
    md: '8px',
    lg: '16px',
  },
  fontFamily: {
    body: 'Nunito Sans',
    display: 'Hind Siliguri',
    fallback: 'sans-serif',
  },
  fontSize: {
    xs3: '12px',
    xs2: '14px',
    xs: '16px',
    sm: '18px',
    md: '20px',
    lg: '24px',
    xl: '32px',
    xl2: '40px',
    xl3: '48px',
  },
  fontWeight: {
    xs: 400,
    sm: 400,
    md: 400,
    lg: 500,
    xl: 600,
    xl2: 700,
    xl3: 900,
  },
  lineHeight: {
    sm: '20px',
    md: '24px',
    lg: '48px',
  },
  letterSpacing: {
    sm: '0.01em',
    md: '0.02em',
    lg: '0.05em',
  },
  typography: {
    h1: {
      fontSize: 'var(--ttf-fontSize-lg)',
      fontWeight: 'var(--ttf-fontWeight-xl)',
      lineHeight: '32px',
      letterSpacing: 'var(--ttf-letterSpacing-sm)',
      fontFamily: 'var(--ttf-fontFamily-display)',
    },
    h2: {
      fontSize: 'var(--ttf-fontSize-sm)',
      fontWeight: 'var(--ttf-fontWeight-xl)',
      lineHeight: '26px',
      letterSpacing: 'var(--ttf-letterSpacing-sm)',
      fontFamily: 'var(--ttf-fontFamily-display)',
    },
    h3: {
      fontSize: 'var(--ttf-fontSize-xs)',
      fontWeight: 'var(--ttf-fontWeight-xl2)',
      lineHeight: '26px',
      letterSpacing: 'var(--ttf-letterSpacing-sm)',
      fontFamily: 'var(--ttf-fontFamily-display)',
    },
    h4: {
      fontSize: 'var(--ttf-fontSize-xs)',
      fontWeight: 'var(--ttf-fontWeight-xl)',
      lineHeight: '26px',
      letterSpacing: 'var(--ttf-letterSpacing-sm)',
      fontFamily: 'var(--ttf-fontFamily-display)',
    },
    h5: {
      fontSize: 'var(--ttf-fontSize-xs2)',
      fontWeight: 'var(--ttf-fontWeight-xl2)',
      lineHeight: '24px',
      letterSpacing: 'var(--ttf-letterSpacing-lg)',
      fontFamily: 'var(--ttf-fontFamily-display)',
    },
    h6: {
      fontSize: 'var(--ttf-fontSize-xs3)',
      fontWeight: 'var(--ttf-fontWeight-xl2)',
      lineHeight: '24px',
      letterSpacing: 'var(--ttf-letterSpacing-lg)',
      fontFamily: 'var(--ttf-fontFamily-display)',
    },
    display1: {
      fontFamily: 'var(--ttf-fontFamily-display)',
      fontSize: 'var(--ttf-fontSize-xl3)',
      fontWeight: 'var(--ttf-fontWeight-xl2)',
      lineHeight: '56px',
    },
    display2: {
      fontFamily: 'var(--ttf-fontFamily-display)',
      fontSize: 'var(--ttf-fontSize-xl2)',
      fontWeight: 'var(--ttf-fontWeight-xl)',
      lineHeight: '48px',
    },
    body1: {
      fontFamily: 'var(--ttf-fontFamily-body)',
      fontSize: 'var(--ttf-fontSize-xs)',
      fontWeight: 'var(--ttf-fontWeight-xs)',
      lineHeight: 'var(--ttf-lineHeight-md)',
    },
    body2: {
      fontFamily: 'var(--ttf-fontFamily-body)',
      fontSize: 'var(--ttf-fontSize-xs)',
      fontWeight: 'var(--ttf-fontWeight-xl)',
      lineHeight: 'var(--ttf-lineHeight-md)',
    },
    body3: {
      fontFamily: 'var(--ttf-fontFamily-body)',
      fontSize: 'var(--ttf-fontSize-xs2)',
      fontWeight: 'var(--ttf-fontWeight-sm)',
      lineHeight: 'var(--ttf-lineHeight-sm)',
    },
    body4: {
      fontFamily: 'var(--ttf-fontFamily-body)',
      fontSize: 'var(--ttf-fontSize-xs3)',
      fontWeight: 'var(--ttf-fontWeight-xs)',
      lineHeight: 'var(--ttf-lineHeight-sm)',
    },
  },
  components: {
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: '52px',
          fontSize: 'var(--ttf-fontSize-xs)',
          fontWeight: 'var(--ttf-fontWeight-xl)',
          lineHeight: '26px',
          letterSpacing: 'var(--ttf-letterSpacing-sm)',
          fontFamily: 'var(--ttf-fontFamily-display)',
          padding: '8px 20px',
          minWidth: ownerState.size === 'sm' ? '78px' : '200px',
          minHeight: '42px',
          cursor: ownerState.disabled ? 'not-allowed' : 'pointer',
          transition: 'all 0.1s ease-out',
          boxShadow: ownerState.disabled
            ? 'none'
            : ownerState.variant === 'solid'
            ? '0px 10px 24px -8px rgba(10, 36, 128, 0.17)'
            : 'none',
          '&:hover': {
            '& .JoyButton-startDecorator': {
              '& svg': {
                opacity: 0.75,
              },
            },
            '& .JoyButton-endDecorator': {
              '& svg': {
                opacity: 0.75,
              },
            },
          },
        }),
        startDecorator: () => ({
          width: '20px',
          height: '20px',
          marginRight: '4px',
        }),
        endDecorator: () => ({
          width: '20px',
          height: '20px',
          marginLeft: '4px',
        }),
      },
      defaultProps: {
        size: 'lg',
        variant: 'solid',
      },
    },
    JoyModalClose: {
      styleOverrides: {
        root: () => ({
          minWidth: 16,
          minHeight: 16,
          color: '#000',
          fontWeight: 'bold',
          position: 'relative',
          top: 0,
          right: 0,
          '&:hover, &:active, &:focus': {
            color: '#000',
            backgroundColor: 'transparent',
          },
        }),
      },
    },
    JoyTextField: {
      styleOverrides: {
        root: () => ({
          marginBottom: '16px',
          '&.JoyTextField-variantOutlined': {
            marginBottom: '16px',
          },
        }),
      },
    },
    JoyInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: '8px 12px',
          borderRadius: '2px',
          height: '40px',
          fontSize: 'var(--ttf-fontSize-xs)',
          fontWeight: 'var(--ttf-fontWeight-xs)',
          lineHeight: '24px',
          color: '#000',
          backgroundColor: '#fff',
          border: `1px solid ${ownerState.error ? RED : LIGHT_GREY}`,
          '--Input-focusedHighlight': ownerState.error ? RED : LIGHT_GREY,
          '--Input-focusedThickness': '1px',
          '&:hover:not(.Joy-focused)': {
            border: `1px solid ${ownerState.error ? RED : LIGHT_GREY}`,
            color: '#000',
          },
        }),
      },
    },
    JoyFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontFamily: 'var(--ttf-fontFamily-body)',
          fontSize: 'var(--ttf-fontSize-xs2)',
          fontWeight: 'var(--ttf-fontWeight-sm)',
          lineHeight: 'var(--ttf-lineHeight-md)',
          color: theme.palette.text.secondary,
        }),
      },
    },
    JoyCheckbox: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          display: 'flex',
          alignItems: 'center',
          '--Checkbox-size': '18px',
          '&:hover': {
            '& .JoyCheckbox-checkbox': {
              borderColor: `${
                ownerState.checked
                  ? theme.palette.primary.solidBorder
                  : DARK_GREY
              }`,
              borderStyle: 'solid',
              borderWidth: 'var(--variant-borderWidth)',
              backgroundColor: 'transparent',
            },
          },
        }),
        checkbox: ({ ownerState, theme }) => ({
          '--variant-borderWidth': '2px',
          borderRadius: '2px',
          backgroundColor: 'transparent',
          borderColor: `${
            ownerState.checked ? theme.palette.primary.solidBorder : DARK_GREY
          }`,
          borderStyle: 'solid',
          borderWidth: 'var(--variant-borderWidth)',
          '& svg': {
            fill: MEDIUM_BLUE,
          },
        }),
        label: () => ({
          fontFamily: 'var(--ttf-fontFamily-body)',
          fontSize: 'var(--ttf-fontSize-xs)',
          fontWeight: 'var(--ttf-fontWeight-xs)',
          lineHeight: 'var(--ttf-lineHeight-md)',
          color: DARK_GREY,
          marginLeft: '11px',
        }),
      },
    },
    JoyDivider: {
      styleOverrides: {
        root: ({ theme }) => ({
          '--Divider-gap': '16px',
          fontFamily: 'var(--ttf-fontFamily-body)',
          fontSize: 'var(--ttf-fontSize-xs)',
          fontWeight: 'var(--ttf-fontWeight-md)',
          lineHeight: 'var(--ttf-lineHeight-md)',
          color: theme.palette.text.secondary,
          margin: '24px 0',
        }),
      },
    },
  },
});
