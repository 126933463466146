import { Sheet, ModalClose, Button } from '@mui/joy';
import { useLocation } from 'react-router-dom';
import { Logo } from '../Logo/Logo.component';
import { useAuth } from '../../context/AuthContextProvider';

export function ModalHeader() {
  const { currentUser, logout } = useAuth();
  const location = useLocation();
  const { pathname } = location;
  const showLogoutButton = pathname !== '/sent' && pathname !== '/started';
  return (
    <Sheet
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 3,
        pr: 2,
        py: '14px',
        borderBottom: '1px solid #D9E4FE',
        height: 40,
      }}
      data-testid="modal-header"
    >
      <div
        style={{
          position: 'relative',
          top: 3,
        }}
      >
        <Logo color="black" direction="horizontal" size="md" />
      </div>
      {currentUser && showLogoutButton && (
        <Button
          onClick={logout}
          sx={{
            position: 'absolute',
            right: 56,
            top: '50%', // center vertically
            transform: 'translateY(-50%)',
            textDecoration: 'underline',
            maxWidth: 50,
            minWidth: 50,
            fontWeight: 300,
          }}
          variant="plain"
          data-testid="logout-button"
        >
          Logout
        </Button>
      )}
      <ModalClose variant="plain" />
    </Sheet>
  );
}
