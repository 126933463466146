import {
  useContext,
  useState,
  useCallback,
  useMemo,
  createContext,
} from 'react';
import { useAPIKeyDoc } from 'hooks/useAPIKeyDoc';
import { useScannerScans } from 'hooks/useScannerScans';
import { filterScans } from 'utils/filterScans';
import { Avatar } from 'db/types';
import { shareScanFromWidget } from 'db/base';
import { updateDoc } from 'firebase/firestore';
import { trackEvent } from 'utils/trackEvent';
import { SharedScanDataContextState } from './types';
import { useSession } from './SessionProvider';

const SharedScanContext = createContext<SharedScanDataContextState>({
  selectedScanId: null,
  selectScan: () => {},
  shareScan: () => {},
  scanSharingLoading: false,
});

export const useSharedScanContext = () => useContext(SharedScanContext);

export const SharedScanProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data: apiKeyDoc } = useAPIKeyDoc();
  const { data: scans } = useScannerScans();
  const [selectedScanId, setSelectedScanId] = useState<string | null>(null);
  const { data: session } = useSession();
  const [scanSharingLoading, setScanSharingLoading] = useState(false);

  const availableScans = useMemo(() => {
    if (apiKeyDoc && typeof scans !== 'undefined') {
      return filterScans({ scans, permissions: apiKeyDoc.permissions })
        .availableScans;
    }
    return [] as Avatar[];
  }, [apiKeyDoc, scans]);

  const shareScan = useCallback(async () => {
    const scan = availableScans.find(
      (avaliableScan) => avaliableScan.id === selectedScanId
    );
    let scanId = scan?.id;
    if (!scan) {
      scanId = session!.activeScan?.id;
    }
    if (!scanId) throw new Error("Scan not found, or isn't available to share");

    try {
      setScanSharingLoading(true);
      trackEvent('widget_share_scan', {
        scanId,
        userId: session!.activeScan?.userId,
        clientId: apiKeyDoc!.clientId,
      });
      const res = await shareScanFromWidget({
        scanId,
        apiKey: apiKeyDoc!.apiKey,
        userId: session!.activeScan?.userId,
      });
      const scanData = { ...res.data, id: scanId };
      if (scanData) {
        await updateDoc(session!.ref, {
          data: scanData,
        });
      }
      setScanSharingLoading(false);
      return true;
    } catch (error) {
      trackEvent('widget_share_scan_error', {
        scanId,
        userId: session!.activeScan?.userId,
        clientId: apiKeyDoc!.clientId,
      });
      setScanSharingLoading(false);
      throw error;
    }
  }, [apiKeyDoc, availableScans, selectedScanId, session]);

  const selectScan = useCallback((scanId: string) => {
    setSelectedScanId((prevSelectedScanId) => {
      if (prevSelectedScanId === scanId) return null;
      return scanId;
    });
  }, []);

  const value = useMemo(
    () => ({
      selectedScanId,
      selectScan,
      shareScan,
      scanSharingLoading,
    }),
    [selectedScanId, selectScan, shareScan, scanSharingLoading]
  );

  return (
    <SharedScanContext.Provider value={value}>
      {children}
    </SharedScanContext.Provider>
  );
};
