import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { useAPIKeyDoc } from 'hooks/useAPIKeyDoc';

export function WidgetButtonContainer({ children }: { children: JSX.Element }) {
  const { isError } = useAPIKeyDoc();

  return (
    <Sheet
      sx={{
        maxWidth: '100%',
        py: 1,
        px: 2,
        bgcolor: 'background.paper',
        boxShadow: 1,
        borderRadius: 8,
        width: 'fit-content',
      }}
    >
      {isError ? (
        <Typography level="h3" color="danger">
          Invalid API key and/or clientId!
        </Typography>
      ) : (
        children
      )}
    </Sheet>
  );
}
