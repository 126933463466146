import { Sheet, Typography, Button } from '@mui/joy';
import { ReactComponent as AvatarIcon } from 'assets/icons/avatar-icon.svg';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { fetchClientData } from 'hooks/useClientData';
import { useAPIKeyDoc } from 'hooks/useAPIKeyDoc';

export const NoScans = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: apiKeyDoc } = useAPIKeyDoc();
  return (
    <Sheet
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      data-testid="no-scans"
    >
      <AvatarIcon style={{ marginTop: 24 }} />
      <Typography level="h2" sx={{ mt: 2 }}>
        No avatars found
      </Typography>
      <Typography
        level="body1"
        sx={{ mt: 0.5, textAlign: 'center', color: '#7C7C89' }}
      >
        Create a new avatar below or click “Refresh list” to fetch any new
        avatars you’ve created.
      </Typography>
      <Button
        sx={{ mt: 2, mb: '34px' }}
        onClick={() => {
          navigate('/create');
        }}
        onMouseEnter={() => {
          // preload the client data query
          queryClient.prefetchQuery('clientData', () =>
            fetchClientData(apiKeyDoc?.clientId)
          );
        }}
      >
        Create a new avatar
      </Button>
    </Sheet>
  );
};
