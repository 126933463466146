import { useEffect } from 'react';
import { Sheet, Typography } from '@mui/joy';
import { ReactComponent as ScanRequestIcon } from 'assets/icons/request-scan-icon.svg';
import { useAPIKeyDoc } from 'hooks/useAPIKeyDoc';
import { trackEvent } from 'utils/trackEvent';
import { useSession } from 'context/SessionProvider';
import { Navigate } from 'react-router-dom';

const ProgressStarted = () => {
  const { data: apiKeyDoc } = useAPIKeyDoc();
  const { data: session } = useSession();

  useEffect(() => {
    trackEvent('widget_scan_started', {
      clientId: apiKeyDoc?.clientId,
    });
  }, []);

  // navigate to landing page if the session is IDLE
  if (session && session.progress === 'IDLE') {
    return <Navigate to="/" />;
  }

  return (
    <Sheet
      sx={{
        mt: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        px: 3,
      }}
      data-testid="started-view"
    >
      <ScanRequestIcon />
      <Typography
        level="display2"
        sx={{
          mt: 3,
          mb: 1,
          fontWeight: 700,
          fontSize: '36px',
          lineHeight: '42px',
        }}
      >
        Almost there!
      </Typography>
      <Typography
        level="body1"
        sx={{ color: 'text.secondary', textAlign: 'center' }}
      >
        Follow the instructions on the TrueToForm scan app.{' '}
      </Typography>
      <Typography level="h2" sx={{ mt: 3 }}>
        Need to restart?
      </Typography>
      <Typography
        level="body1"
        sx={{ color: 'text.secondary', textAlign: 'center', mt: 0.5 }}
      >
        Close this tab and click on the TrueToForm <br />
        'SEND MEASUREMENTS' button again.
      </Typography>
    </Sheet>
  );
};

export default ProgressStarted;
