import styled from '@mui/joy/styles/styled';
import { MUIStyledCommonProps } from '@mui/system';
import { Sheet } from '@mui/joy';
import { Logo } from '../Logo/Logo.component';
import { Loader } from '../Loader';
import { useModalContext } from '../Modal/Modal.context';

interface ContainerProps extends MUIStyledCommonProps {
  minHeight?: number;
}

// 40 is the height of the header
const Container = styled('div')(({ minHeight = 628 - 40 }: ContainerProps) => ({
  padding: '26px 32px',
  minHeight,
}));

function SuspenseLoader() {
  const { dimensions } = useModalContext();
  return (
    <Sheet
      sx={{
        display: 'flex',
        alignItems: 'center',
        minHeight: dimensions.height - 40, // 40 is the height of the header
        height: dimensions.height - 40,
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '32px',
      }}
    >
      <Logo color="black" direction="vertical" size="lg" />
      <Loader />
    </Sheet>
  );
}

export { Container, SuspenseLoader };
