type RetryParams = {
  retries: number;
  delay: number;
};

export async function retry<F extends () => any>(
  fn: F,
  { retries = 3, delay = 300 }: RetryParams
): Promise<ReturnType<F>> {
  let result;
  try {
    result = await fn();
  } catch (e) {
    if (retries > 0) {
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, delay));
      return retry(fn, { retries: retries - 1, delay });
    }
    throw e;
  }
  return result;
}
