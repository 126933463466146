import Sheet from '@mui/joy/Sheet';
import { useLocation } from 'react-router-dom';

export const LoaderContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { pathname } = useLocation();
  const isUnavailable = pathname === '/unavailable';
  return (
    <Sheet
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: isUnavailable ? 470 : 360,
      }}
    >
      {children}
    </Sheet>
  );
};
