import {
  useContext,
  createContext,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useMessagesApi } from 'context/MessagesApiProvider';
import { MessageType } from 'context/types';
import { isWidgetIframe } from 'utils/isWidgetIframe';

export interface ModalContextState {
  dimensions: {
    width: number;
    height: number;
  };
  setDimensions: (dimensions: { width: number; height: number }) => void;
  isResizing: boolean;
  closePage: () => void;
  showCloseConfirmation: boolean;
  hideCloseConfirmation: () => void;
  openCloseConfirmation: () => void;
}

const ModalContext = createContext({} as ModalContextState);

ModalContext.displayName = 'ModalContext';

function ModalContextProvider(props: { children: React.ReactNode }) {
  const [dimensions, setDimensionsState] = useState({
    width: 456,
    height: 628,
  });
  const [isResizing, setIsResizing] = useState(false);
  const prevDiections = useRef(dimensions);
  const { sendMessage } = useMessagesApi();
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  const setDimensions = useCallback(
    (params: { width: number; height: number }) => {
      const { width, height } = params;
      setDimensionsState({ width, height });
      const hasChanged =
        prevDiections.current.width !== width ||
        prevDiections.current.height !== height;
      if (hasChanged) {
        setIsResizing(true);
        setTimeout(() => {
          setIsResizing(false);
        }, 300);
      }
      prevDiections.current = { width, height };
    },
    []
  );

  const closePage = useCallback(() => {
    if (isWidgetIframe()) {
      return sendMessage({
        type: MessageType.WIDGET_CLOSED,
        payload: {},
      });
    }
    window.close();
  }, []);

  const hideCloseConfirmation = useCallback(() => {
    setShowCloseConfirmation(false);
  }, []);

  const openCloseConfirmation = useCallback(() => {
    setShowCloseConfirmation(true);
  }, []);

  const value = useMemo(
    () => ({
      dimensions,
      setDimensions,
      isResizing,
      closePage,
      showCloseConfirmation,
      hideCloseConfirmation,
      openCloseConfirmation,
    }),
    [
      dimensions,
      setDimensions,
      isResizing,
      closePage,
      showCloseConfirmation,
      openCloseConfirmation,
      hideCloseConfirmation,
    ]
  );

  return <ModalContext.Provider value={value} {...props} />;
}

const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(
      'useModalContext must be used within a ModalContextProvider'
    );
  }
  return context;
};

export { ModalContextProvider, useModalContext };
