import { logEvent } from 'firebase/analytics';
import { analytics } from 'db/base';
import mixpanel from 'mixpanel-browser';

const events = [
  'widget_button_impression',
  'widget_new_session',
  'widget_login',
  'widget_login_error',
  'widget_message_received',
  'widget_message_sent',
  'widget_qr_image_loaded',
  'widget_share_scan',
  'widget_share_scan_error',
  'widget_scan_started',
  'widget_scan_submitting',
] as const;

// make event name type as enum of events array
type EventName = typeof events[number];

// make event properties type as object
type EventProperties = Record<string, any>;

// track event function
export const trackEvent = (
  eventName: EventName,
  eventProperties?: EventProperties
) => {
  // track event in mixpanel
  mixpanel.track(eventName, eventProperties);
  // track event in firebase analytics
  logEvent(analytics, eventName, eventProperties);
};
