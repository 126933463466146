/* eslint-disable react/no-unstable-nested-components */
import './app.css';
import { CssVarsProvider } from '@mui/joy/styles';
import { QueryClientProvider, QueryClient } from 'react-query';
// context
import { AuthContextProvider } from 'context/AuthContextProvider';
import mixpanel from 'mixpanel-browser';
import { RouterProvider } from 'react-router-dom';
import { routes } from './Router';
import { joyTheme } from './joyTheme';

const queryClient = new QueryClient();

if (typeof process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN === 'string') {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN);
}

function App() {
  return (
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <CssVarsProvider theme={joyTheme}>
          <RouterProvider router={routes} />
        </CssVarsProvider>
      </QueryClientProvider>
    </AuthContextProvider>
  );
}

export default App;
