import { Sheet, Typography } from '@mui/joy';
import format from 'date-fns/format';
import { ReactComponent as CheckIcon } from 'assets/icons/check-mark.svg';
import { useAPIKeyDoc } from 'hooks/useAPIKeyDoc';
import { useSession } from 'context/SessionProvider';
import { useMessagesApi } from 'context/MessagesApiProvider';
import { MessageType } from 'context/types';
import { Logo } from 'components/Logo/Logo.component';

import { redirectUser } from 'utils/redirectUser';
import { isInsideIframe } from 'utils/isInsideIframe';
import { isWidgetIframe } from 'utils/isWidgetIframe';
import { autoId } from 'utils/autoId';
import { trackEvent } from 'utils/trackEvent';
import { useEffect } from 'react';
import { WidgetButtonContainer } from './Container';

export function WidgetButton() {
  const { isLoading, data: apiKeyDoc } = useAPIKeyDoc();
  const { createNewSession, data: session } = useSession();
  const { sendMessage } = useMessagesApi();
  const permissions = apiKeyDoc?.permissions ?? [];
  const canReadAvatar = permissions.includes('readAvatarFile') ?? false;

  useEffect(() => {
    if (!isInsideIframe() || isWidgetIframe()) return;
    trackEvent('widget_button_impression', {
      source: window.location.href,
      clientId: apiKeyDoc?.clientId ?? '',
    });
  }, []);

  useEffect(() => {
    if (!isLoading && apiKeyDoc) {
      // send WidgetReady message to host window
      sendMessage({
        type: MessageType.WIDGET_READY,
        payload: {
          status: 'ready',
        },
      });
    }
  }, [isLoading]);

  const handleClick = async () => {
    const sessionId = autoId();
    trackEvent('widget_new_session', {
      sessionId,
      clientId: apiKeyDoc?.clientId ?? '',
    });
    createNewSession(sessionId);
    if (apiKeyDoc) {
      redirectUser({
        sessionId,
        apiKey: apiKeyDoc.apiKey,
        clientId: apiKeyDoc.clientId,
        redirectUrl: apiKeyDoc.origin,
      });
    }
  };

  // if not in Iframe
  if (!isInsideIframe() || isWidgetIframe()) return null;

  const getButtonText = () => {
    let text = 'Send measurements';
    if (canReadAvatar && !session?.data) {
      text = 'Import avatar';
    } else if (session?.data && canReadAvatar) {
      text = 'Avatar Sent';
    } else if (session?.data) {
      text = 'Measurements Sent';
    }
    return text;
  };

  return (
    <WidgetButtonContainer>
      <Sheet
        variant="outlined"
        sx={{
          width: 270,
          borderRadius: 'md',
          padding: '6px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          backgroundColor: 'background.default',
          flexDirection: 'column',
          borderColor: 'var(--ttf-palette-neutral-outlinedBorder)',
          '&:disabled': {
            cursor: 'not-allowed',
          },
        }}
        component="button"
        onClick={handleClick}
        disabled={isLoading}
        id="ttf-widget-button"
      >
        <Sheet
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          {session?.data && <CheckIcon />}
          <Typography
            level="h4"
            sx={{
              textTransform: 'uppercase',
              color: 'text.primary',
              transition: 'color 0.2s ease-in-out',
            }}
          >
            {getButtonText()}
          </Typography>
        </Sheet>
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          {session?.data ? (
            <Typography level="body4">
              {session.data.name} •{' '}
              {format(session.data.timestamp * 1000, 'MMM dd yyyy hh:mm a')}
            </Typography>
          ) : (
            <>
              <Typography
                level="body4"
                sx={{
                  color: 'text.tertiary',
                }}
              >
                Powered By
              </Typography>
              {/* this logo is center but looks to the eyes to be above the text so I set top:1 */}
              <div
                style={{
                  alignSelf: 'center',
                  position: 'relative',
                  top: 1,
                }}
              >
                <Logo size="sm" color="#8A8A98" />
              </div>
            </>
          )}
        </div>
      </Sheet>
    </WidgetButtonContainer>
  );
}
