import Sheet from '@mui/joy/Sheet';

export const ScansContainer = ({
  children,
  testId,
  isRefetching,
}: {
  children: React.ReactNode;
  testId: string;
  isRefetching: boolean;
}) => (
  <Sheet
    sx={{
      position: 'relative',
      minHeight: 360,
      maxHeight: 360,
      overflowY: !isRefetching ? 'scroll' : 'hidden',
      pb: '112px',
      mr: -1, // the size of the scrollbar
      '&::-webkit-scrollbar': {
        width: 8,
        position: 'absolute',
        right: 0,
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'rgba(6, 111, 249, 0.6)',
        borderRadius: 4,
      },
    }}
    data-testid={testId}
  >
    {children}
  </Sheet>
);
