import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  initializeFirestore,
  connectFirestoreEmulator,
} from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions';
import { getStorage } from 'firebase/storage';

// types
import { ScanData, ShareScanFromWidgetRequest } from './types';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = initializeFirestore(app, {
  experimentalForceLongPolling: process.env.NODE_ENV !== 'production',
});
export const auth = getAuth(app);
export const storage = getStorage(app);
// decalre callable functions
const functions = getFunctions(app);

// @ts-ignore
global.emulatorsStarted = false;

if (
  process.env.NODE_ENV !== 'production' &&
  process.env.REACT_APP_BACKEND_EMULATORS === 'on'
) {
  // @ts-ignore
  global.emulatorsStarted = true;
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export const shareScanFromWidget = httpsCallable<
  ShareScanFromWidgetRequest,
  ScanData
>(functions, 'shareScanFromWidget');
